<template>
  <transition :name="from">
    <div
      v-if="show"
      class="overlay-backdrop"
      @click="$emit('close')"
    >
      <div class="product-modal" @click.stop="() => true">
        <img src="@/assets/images/close.svg" alt="close icon" class="close-img" loading="lazy" @click="$emit('close')"/>
        <div class="products-container">
          <div class="product-header">
            <img :src="require(`@/assets/images/${highlightImg}.svg`)" alt="real-estate" class="solution-image" loading="lazy"/>
            <div class="product-details">
              <div class="product-text">
                <span>{{ products[0].caption }}</span>
                <span>{{ products[0].product_highlight }}</span>
                <span>RD${{ products[0].price | currencyFormat }} / Mensual {{ products[0].months_to_expire === 12 ? "facturado anual" : products[0].months_to_expire === 6 ? 'facturado semestral' : products[0].months_to_expire === 3 ? "facturado trimestral" : '' }}</span>
              </div>
              <div class="product-text-bottom">
                <span>Total <strong>RD${{ products[0].price * products[0].months_to_expire | currencyFormat }}</strong></span>
                <span>Precios no incluyen ITBIS</span>
              </div>
            </div>
          </div>
          <div class="product-type">
              <h2>Selecciona tu tipo de cuenta</h2>
              <span v-if="(selectedProduct.product_sub_type === 'biometric' && userStatus.user_identity_verified) || (selectedProduct.product_sub_type === 'enterprise' && userStatus.user_business_identity_verified)">Su cuenta ha sido previamente verificada exitosamente.</span>
              <div class="options">
                <div
                  class="option biometric"
                  :class="{'selected': selectedProduct.product_sub_type === 'biometric'}"
                  @click="$emit('selectProduct', 'biometric')">
                  <div class="opt-header">
                    <img src="@/assets/images/user-verification-personal.svg" alt=""/>
                    Personal
                    <span class="opt-circle" :class="{'selected': selectedProduct.product_sub_type === 'biometric'}">
                      <img v-if="selectedProduct.product_sub_type === 'biometric'" src="@/assets/images/checked.svg" alt="">
                    </span>
                  </div>
                  <div class="opt-body">
                    <h3>Características:</h3>
                    <div class="opt-bullets">
                      <div class="bullet">
                        <img src="@/assets/images/check-gold.svg" alt="check"/>
                        <span>Es para cuentas de ventas personales o de un negocio informal no registrado</span>
                      </div>
                      <div class="bullet">
                        <img src="@/assets/images/check-gold.svg" alt="check"/>
                        <span>Se requiere una cédula de República Dominicana</span>
                      </div>
                      <div class="bullet">
                        <img src="@/assets/images/check-gold.svg" alt="check"/>
                        <span>Completa la verificación 100% en línea</span>
                      </div>
                    </div>
                  </div>
                  <div class="bottom-text">
                    <img src="@/assets/images/alert-yellow.svg" alt="check"/>
                    El proceso de verificación es en tiempo real
                  </div>
                </div>
                <div
                  class="option enterprise"
                  :class="{'selected': selectedProduct.product_sub_type === 'enterprise'}"
                  @click="$emit('selectProduct', 'enterprise')">
                  <div class="opt-header">
                    <img src="@/assets/images/user-verification-enterprise.svg" alt=""/>
                    Empresarial
                    <span class="opt-circle" :class="{'selected': selectedProduct.product_sub_type === 'enterprise'}">
                      <img v-if="selectedProduct.product_sub_type === 'enterprise'" src="@/assets/images/checked.svg" alt="">
                    </span>
                  </div>
                  <div class="opt-body">
                    <h3>Características:</h3>
                    <div class="opt-bullets">
                      <div class="bullet">
                        <img src="@/assets/images/check-gold.svg" alt="check">
                        <span>Es para empresas constituidas localmente</span>
                      </div>
                      <div class="bullet">
                        <img src="@/assets/images/check-gold.svg" alt="check">
                        <span>Requiere Certificación de Inscripción al Registro Nacional de Contribuyentes (RNC)</span>
                      </div>
                      <div class="bullet">
                        <img src="@/assets/images/check-gold.svg" alt="check">
                        <span>Se requiere Registro Mercantil</span>
                      </div>
                    </div>
                  </div>
                  <div class="bottom-text">
                    <img src="@/assets/images/alert-yellow.svg" alt="check"/>
                    El proceso toma de 1 a 3 días laborables
                  </div>
                </div>
              </div>
              <div
                class="action-btn"
                :class="Object.keys(selectedProduct).length > 0 ? 'active' : 'disabled'"
                @click="$emit('addSelectedProductToCart', selectedProduct)">
                Confirmar
              </div>
            </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import qs from 'qs';
// import CloseIcon from '~/assets/images/close.svg'

export default {
  name: 'SelectProductModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    from: {
      default: 'slide-up',
      validator: value =>
        ['slide-up', 'slide-down'].includes(value)
    },
    products: {
      type: Array,
      default: []
    },
    selectedProduct: {
      type: Object,
      default: {}
    },
    highlightImg: {
      type: String,
      default: ''
    },
    userEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userStatus: {}
    }
  },
  mounted() {
    let q = qs.stringify({
        q: {
            email_eq: this.userEmail
        }
    });
    this.$api.get(`/api/v1/app_users?${q}`)
    .then(res => {
      this.userStatus = res.data.data[0]
    })
    .catch(err => {
      console.log(err)
    })
  },
  computed: {
    fromRight () {
      return this.from === 'slide-right'
    }
  }
}
</script>

<style lang="css" scoped>
  .overlay-backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 90%;
  }
  .product-modal > img {
    background: #fff;
    box-shadow: 0 1px 0 rgb(27 31 35 / 5%), 0 6px 13px rgb(0 0 0 / 5%);
    border-radius: 100%;
    padding: 6px;
    width: 40px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .product-modal .products-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-radius: 16px;
    padding: 16px;
    max-height: 480px;
    overflow: scroll;
  }
  .product-modal .products-container .product-header {
    padding: 8px;
    border: 1px solid #DFDFDF;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  }
  .product-modal .products-container .product-header img {
    width: 65px;
  }
  .product-modal .products-container .product-header .product-details {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    width: 100%;
  }
  .product-modal .products-container .product-header .product-details .product-text {
    display: flex;
    flex-direction: column;
  }
  .product-header .product-details .product-text span:nth-child(1) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #1D477A;
  }
  .product-header .product-details .product-text span:nth-child(2),
  .product-header .product-details .product-text span:nth-child(3) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #404040;
  }
  .product-header .product-details .product-text span:nth-child(3) {
    padding-bottom: 4px;
    border-bottom: 1px solid #DFDFDF;
  }
  .product-header .product-details .product-text-bottom {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }
  .product-header .product-details .product-text-bottom span:nth-child(1) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #5E5E5E;
  }
  .product-header .product-details .product-text-bottom span:nth-child(1) strong {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #1F1F1F;
    margin-left: 5px;
  }
  .product-header .product-details .product-text-bottom span:nth-child(2) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #5E5E5E;
  }
  
  .product-modal .products-container .product-type {
    display: flex;
    flex-direction: column;
  }
  .product-modal .products-container .product-type h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #1D477A;
    margin: 0;
    /* margin-bottom: 8px; */
  }
  .product-modal .products-container .product-type > span {
    font-size: 11px;
  }
  .product-modal .products-container .product-type .options {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }
  .product-modal .products-container .product-type .options .option {
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    padding: 8px;
  }
  .product-modal .products-container .product-type .options .option.selected {
    border: 2px solid #CBDEF5;
  }
  .product-modal .products-container .product-type .options .option:first-child {
    margin-bottom: 8px;
  }
  .product-modal .products-container .product-type .options .option .opt-header {
    text-align: center;
    position: relative;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #1F1F1F;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #dfdfdf;
  }
  .product-modal .products-container .product-type .options .option .opt-header > img {
    display: none;
  }
  .products-container .product-type .options .option .opt-header span {
    width: 20px;
    height: 20px;
    background: none;
    border: 3px solid #7cace7;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    /* top: 14px; */
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .products-container .product-type .options .option .opt-header .opt-circle.selected {
    background: #7cace7;
    border: 3px solid #7cace7;
  }

  .product-modal .products-container .product-type .options .option .opt-body {
    display: flex;
    flex-direction: column;
  }
  .product-modal .products-container .product-type .options .option .opt-body h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #1F1F1F;
    margin-bottom: 5px;
  }
  .product-modal .products-container .product-type .options .option .opt-body .opt-bullets {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }
  .options .option .opt-body .opt-bullets .bullet {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .options .option .opt-body .opt-bullets .bullet img {
    width: 10px;
    margin-right: 8px;
  }
  .options .option .opt-body .opt-bullets .bullet span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #404040;
  }
  .options .option .bottom-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #404040;
    border-top: 1px solid #dfdfdf;
    margin: 0 -8px;
    padding: 8px 8px 0;
  }
  .options .option .bottom-text img {
    margin-right: 8px;
    width: 12px;
  }

  .products-container .product-type .action-btn {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    height: 36px;
    background: #3F69A5;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #fff;
  }
  .products-container .product-type .action-btn.disabled {
    opacity: 0.4;
  }
  .products-container .product-type .action-btn.active {
    opacity: 1;
    cursor: pointer;
  }

  @media (min-width: 641px) {
    .product-modal {
      max-width: 800px;
    }
    .product-modal .products-container {
      max-height: 100%;
      overflow: auto;
    }
    .product-modal .products-container .product-header {
      padding: 8px 35px;
    }
    .product-modal .products-container .product-header img {
      width: 120px;
    }
    .product-modal .products-container .product-header .product-details {
      margin-left: 50px;
      flex-direction: row;
      justify-content: space-between;
    }
    .product-header .product-details .product-text span:nth-child(1) {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px
    }
    .product-header .product-details .product-text span:nth-child(2),
    .product-header .product-details .product-text span:nth-child(3) {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
    }
    .product-header .product-details .product-text span:nth-child(3) {
      border-bottom: none;
    }
    .product-header .product-details .product-text-bottom {
      margin-top: 0px;
      padding-left: 16px;
      border-left: 1px solid #dfdfdf;
    }
    .product-header .product-details .product-text-bottom span:nth-child(1) {
      font-size: 13px;
      line-height: 20px;
    }
    .product-header .product-details .product-text-bottom span:nth-child(1) strong {
      display: block;
      margin-left: 0;
      font-family: 'Montserrat';
      font-size: 18px;
      line-height: 24px;
    }
    .product-header .product-details .product-text-bottom span:nth-child(2) {
      font-size: 11px;
    }
    .product-modal .products-container .product-type h2 {
      font-weight: 700;
      font-size: 18px;
    }
    .product-modal .products-container .product-type > span {
      font-size: 13px;
    }
    .product-modal .products-container .product-type .options {
      flex-direction: row;
    }
    .product-modal .products-container .product-type .options .option {
      border: 2px solid #dfdfdf;
      cursor: pointer;
    }
    .product-modal .products-container .product-type .options .option:first-child {
      margin-bottom: 0;
      margin-right: 5px;
    }
    .product-modal .products-container .product-type .options .option:nth-child(2) {
      margin-left: 5px;
    }
    .product-modal .products-container .product-type .options .option .opt-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
    }
    .product-modal .products-container .product-type .options .option .opt-header > img {
      width: 135px;
      display: block;
    }
    .product-modal .products-container .product-type .options .option .opt-body h3 {
      font-size: 15px;
      line-height: 24px;
    }
    .options .option .opt-body .opt-bullets .bullet span {
      font-size: 13px;
      line-height: 20px;
    }
    .options .option .bottom-text {
      font-size: 13px;
      line-height: 20px;
    }
  }
</style>